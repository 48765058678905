* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background: #f2f2f2;
  margin: 0 10px;
}

.audio-player {
  background: #c1b6bc;
  max-width: 600px;
  
}

.inner {
  margin: 0 auto;
  padding: 20px;
}

.play-list {
  gap: 20px;
  margin-bottom: 10px;
  
}

/* audio info */
.audio-info {
  display: float;
  gap: 20px;
}

.text {
  color: #000;
}

.title {
  color: #fff;
  font-weight: 100;
  font-size: 32px;
  margin-bottom: 0;
  padding: 2px;
  line-height: 37px;
  background-color: rgba(0,0,0,.8);
}

/* audio image */
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.audio-image {
  width: 300px;
  height: 300px;
  background: #1e1e1e; 
}

.audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #989898;
}

.audio-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

/* controls */
.controls-wrapper {
  display: float;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.controls button, .volume button {
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}

button svg {
  font-size: 20px;
}

.volume {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100px;
}

.volume button {
  display: flex;
  margin: 0;
}

.progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

/* time */
.time {
  color: #333;
  font-size: 13px;
  line-height: 46px;
}

.time.current {
  color: #f50;
}

